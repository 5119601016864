.ScheduleTime {
  display: inline-block;
  cursor: pointer;
  color: #22303e;
  padding: 10px 50px;
  background-color: #fff;
  border: 1px solid #8b8f93;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;

  &:hover {
    border: 1px solid rgba(228, 86, 9, 0.7);
  }

  &.selected {
    border: 1px solid rgba(228, 86, 9, 1);
  }

  &.disabled {
    border: none;
    cursor: auto;
    border: 1px solid #8b8f93;
    background-color: #e9e9e9;
    box-shadow: none;
  }
}

@media (max-width: 600px) {
  .ScheduleTime {
    padding: 10px 10px;
    &:hover {
      border: 1px solid #8b8f93;
    }
    &.selected {
      border: 1px solid rgba(228, 86, 9, 1);
    }
  }
}
