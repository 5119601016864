.CourtCard {
  font-size: 16px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
  height: 230px;
  width: 200px;
  color: #22303e;

  &-info {
    padding: 0 10px;
    &--title {
      position: relative;
      top: 35%;
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 600;
    }

    &--description {
      border-top: 1px solid #d8d8d8;
      position: relative;
      font-size: 11px;
      padding-top: 10px;
      top: 50%;
    }
    height: 80%;
  }

  &-button {
    display: flex;
    color: #fff;
    cursor: pointer;
    opacity: 0.8;
    justify-content: center;
    align-items: center;
    height: 20%;
  }

  &-button:hover {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .CourtCard {
    &-button {
      opacity: 1;
    }
  }
}
