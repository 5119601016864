.ReservationsButton {
    display: flex;
    height: 120px;
    padding-left: 70px;
    padding-right: 70px;
    align-items: center;
    justify-content: center;
    cursor: pointer;    
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 600px) {
    .ReservationsButton {
        display: flex;
        height: 60px; 
        width: 220px;
        cursor: pointer;    
        font-weight: 600;
        font-size: 14px; 
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 8px; 
    }
}
