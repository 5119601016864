.TimeSlots {
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .TimeSlots {
    gap: 15px;
  }
}
